import axios from 'axios'
import { getToken } from '@/utils/auth'
const token = getToken('rvToken')
export function export2File(obj) {
  axios({
    method: 'post',
    url: obj.url,
    data: obj.data,
    headers: {
      Authorization: 'Bearer ' + token
    },
    responseType: 'arraybuffer'
  }).then((res) => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    link.download = obj.fileName + '.xlsx' //下载的文件名
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}